import { differenceInYears } from 'date-fns';
import { ALERT_KIND, RECORD_STATUS, RECORD_STATUS_DETAIL, RECORD_STEPS } from './enums';

const environment = process.env.NODE_ENV;

export const API_URL =
  environment === 'production' ? 'https://api.subsidy.pe' : 'http://localhost:3001';

export const ERROR_MESSAGES = {
  REQUIRED: 'Este campo es obligatorio',
  REQUIRED_PLURAL: 'Este campo es obligatorio',
  WRONG_EMAIL: 'Ingrese un correo válido',
  DIGITS_ONLY: 'Ingrese solo números',
  LENGTH: (length: number) => `Ingrese ${length} caracteres`,
  MIN_LENGTH: (minLength: number) => `Ingrese como mínimo ${minLength} caracteres`,
  MAX_LENGTH: (maxLength: number) => `Ingrese como máximo ${maxLength} caracteres`,
};

export const REQUIRED_RULE = {
  required: 'Este campo es obligatorio',
};

export const USER_POSITIONS = {
  super_admin: 'Administrador',
  admin: 'Usuario',
  user: 'Usuario',
};

export const RECORD_CONSISTENCY_OPTIONS = [
  { value: 'ACCIDENTE COMÚN', name: 'ACCIDENTE COMÚN' },
  { value: 'ACCIDENTE DE TRABAJO', name: 'ACCIDENTE DE TRABAJO' },
  { value: 'ACCIDENTE DE TRÁNSITO', name: 'ACCIDENTE DE TRÁNSITO' },
  { value: 'ENFERMEDAD COMÚN', name: 'ENFERMEDAD COMÚN' },
  { value: 'ENFERMEDAD PROFESIONAL', name: 'ENFERMEDAD PROFESIONAL' },
  { value: 'MATERNIDAD', name: 'MATERNIDAD' },
];

export const GENERAL_STATUS_TEXT = {
  valid: 'VIGENTE',
  closed: 'CERRADO',
};

export const RECORD_STATUS_TEXT: {
  [key in RECORD_STATUS]: string;
} = {
  new: 'NUEVO EXPEDIENTE',
  in_exchange: 'EN CANJE',
  in_loan: 'EN PRESTACIONES',
  resolved: 'RESOLUCION',
  not_recovered: 'NO RECUPERADO',
  charged: 'COBRADO',
  discount: 'DESCUENTO',
};

export const RECORD_STATUS_DETAIL_TEXT = {
  completed: 'COMPLETO',
  missing_data: 'FALTA DOCUMENTACION',

  registered_viva: 'REGISTRADO EN VIVA',
  registered_cevit: 'PENDIENTE POR CEVIT',
  observed: 'OBSERVADO',
  imeci: 'IMECI',
  enter_comeci: 'INGRESA A COMECI',
  citt_issued: 'CITT EMITIDO',

  //pending_upe: 'PENDIENTE POR UPE',
  pending_upe: 'CON FECHA DE PAGO',
  with_observation: 'CON OBSERVACION',
  //with_payment_date: 'CON FECHA DE PAGO',
  with_payment_date: 'PENDIENTE POR UPE',

  pending_reception: 'PENDIENTE RECEPCIÓN',
  to_enter_reconsideration: 'PARA INGRESAR RECURSO DE RECONSIDERACION',
  waiting_reconsideration: 'EN ESPERA DE RESPUESTA DE RECURSO DE RECONSIDERACIÓN',
  to_enter_appeal: 'PARA INGRESAR APELACIÓN',
  waiting_appeal: 'EN ESPERA DE RESPUESTA DE APELACIÓN',
  judicial_process: 'PROCESO JUDICIAL',

  late_request: 'SOLICITUD EXTEMPORANEA',
  no_sustain: 'SIN SUSTENTO',
  allowance_exceeded: 'EXCEDIO DIAS DE SUBSIDIO',
  no_response: 'NO SE RESPONDIO RESOLUCION',
  qualifying_guide: 'GUIA CALIFICADORA',
  deceased: 'FALLECIDO',

  recovered: 'RECUPERADO',
  partial_refund: 'PARCIAL REINTEGRO',
  partial_resolution: 'PARCIAL RESOLUCION',
};

export const RECORD_STATUS_OPTIONS: {
  [key in RECORD_STATUS]: Array<any>;
} = {
  [RECORD_STATUS.NEW]: [
    {
      value: RECORD_STATUS_DETAIL.COMPLETED,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.COMPLETED],
    },
    {
      value: RECORD_STATUS_DETAIL.MISSING_DATA,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.MISSING_DATA],
    },
  ],
  [RECORD_STATUS.IN_EXCHANGE]: [
    {
      value: RECORD_STATUS_DETAIL.REGISTERED_VIVA,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.REGISTERED_VIVA],
    },
    {
      value: RECORD_STATUS_DETAIL.REGISTERED_CEVIT,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.REGISTERED_CEVIT],
    },
    {
      value: RECORD_STATUS_DETAIL.OBSERVED,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.OBSERVED],
    },
    {
      value: RECORD_STATUS_DETAIL.IMECI,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.IMECI],
    },
    {
      value: RECORD_STATUS_DETAIL.ENTER_COMECI,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.ENTER_COMECI],
    },
    {
      value: RECORD_STATUS_DETAIL.CITT_ISSUED,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.CITT_ISSUED],
    },
  ],
  [RECORD_STATUS.IN_LOAN]: [
    {
      value: RECORD_STATUS_DETAIL.PENDING_UPE,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.PENDING_UPE],
    },
    {
      value: RECORD_STATUS_DETAIL.WITH_OBSERVATION,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.WITH_OBSERVATION],
    },
    {
      value: RECORD_STATUS_DETAIL.WITH_PAYMENT_DATE,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.WITH_PAYMENT_DATE],
    },
  ],
  [RECORD_STATUS.RESOLVED]: [
    {
      value: RECORD_STATUS_DETAIL.PENDING_RECEPTION,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.PENDING_RECEPTION],
    },
    {
      value: RECORD_STATUS_DETAIL.TO_ENTER_RECONSIDERATION,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.TO_ENTER_RECONSIDERATION],
    },
    {
      value: RECORD_STATUS_DETAIL.WAITING_RECONSIDERATION,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.WAITING_RECONSIDERATION],
    },
    {
      value: RECORD_STATUS_DETAIL.TO_ENTER_APPEAL,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.TO_ENTER_APPEAL],
    },
    {
      value: RECORD_STATUS_DETAIL.WAITING_APPEAL,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.WAITING_APPEAL],
    },
    {
      value: RECORD_STATUS_DETAIL.JUDICIAL_PROCESS,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.JUDICIAL_PROCESS],
    },
  ],
  [RECORD_STATUS.NOT_RECOVERED]: [
    {
      value: RECORD_STATUS_DETAIL.LATE_REQUEST,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.LATE_REQUEST],
    },
    {
      value: RECORD_STATUS_DETAIL.NO_SUSTAIN,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.NO_SUSTAIN],
    },
    {
      value: RECORD_STATUS_DETAIL.ALLOWANCE_EXCEEDED,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.ALLOWANCE_EXCEEDED],
    },
    {
      value: RECORD_STATUS_DETAIL.NO_RESPONSE,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.NO_RESPONSE],
    },
    {
      value: RECORD_STATUS_DETAIL.QUALIFYING_GUIDE,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.QUALIFYING_GUIDE],
    },
    {
      value: RECORD_STATUS_DETAIL.DECEASED,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.DECEASED],
    },
  ],
  [RECORD_STATUS.CHARGED]: [
    {
      value: RECORD_STATUS_DETAIL.RECOVERED,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.RECOVERED],
    },
    {
      value: RECORD_STATUS_DETAIL.PARTIAL_REFUND,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.PARTIAL_REFUND],
    },
    {
      value: RECORD_STATUS_DETAIL.PARTIAL_RESOLUTION,
      name: RECORD_STATUS_DETAIL_TEXT[RECORD_STATUS_DETAIL.PARTIAL_RESOLUTION],
    },
  ],
  [RECORD_STATUS.DISCOUNT]: [],
};

export const RECORD_STATUS_OPTIONS_STEPS: {
  [key in RECORD_STATUS]: RECORD_STEPS | undefined;
} = {
  [RECORD_STATUS.NEW]: undefined,
  [RECORD_STATUS.IN_EXCHANGE]: RECORD_STEPS.VALIDATION,
  [RECORD_STATUS.IN_LOAN]: RECORD_STEPS.IN_LOAN,
  [RECORD_STATUS.RESOLVED]: RECORD_STEPS.RESOLVED,
  [RECORD_STATUS.NOT_RECOVERED]: RECORD_STEPS.NOT_RECOVERED,
  [RECORD_STATUS.CHARGED]: RECORD_STEPS.CHARGED,
  [RECORD_STATUS.DISCOUNT]: RECORD_STEPS.DISCOUNT,
};

export const RECORD_STATUS_STEPS_VALIDATION: {
  [key in RECORD_STATUS]: Array<RECORD_STEPS>;
} = {
  [RECORD_STATUS.NEW]: [RECORD_STEPS.WORKER, RECORD_STEPS.MEDICAL_REST],
  [RECORD_STATUS.IN_EXCHANGE]: [RECORD_STEPS.VALIDATION],
  [RECORD_STATUS.IN_LOAN]: [RECORD_STEPS.IN_LOAN],
  [RECORD_STATUS.RESOLVED]: [RECORD_STEPS.RESOLVED],
  [RECORD_STATUS.NOT_RECOVERED]: [RECORD_STEPS.NOT_RECOVERED],
  [RECORD_STATUS.CHARGED]: [RECORD_STEPS.CHARGED],
  [RECORD_STATUS.DISCOUNT]: [RECORD_STEPS.DISCOUNT],
};

export const DEFAULT_FILTERS: any = {
  text: '',
  number: {
    from: undefined,
    to: undefined,
  },
  date: {
    from: '',
    to: '',
  },
  select: [],
  all: { value: 'all', name: 'Todos' },
  year: {
    value: new Date().getFullYear().toString(),
    name: new Date().getFullYear().toString(),
  },
  date_kind: { value: 'year_month', name: 'Mes y año' },
  report_type: { value: 'general', name: 'GRÁFICOS GENERALES' },
};

export const MONTH_OPTIONS = [
  { value: 'all', name: 'Todos' },
  { value: '0', name: 'Enero' },
  { value: '1', name: 'Febrero' },
  { value: '2', name: 'Marzo' },
  { value: '3', name: 'Abril' },
  { value: '4', name: 'Mayo' },
  { value: '5', name: 'Junio' },
  { value: '6', name: 'Julio' },
  { value: '7', name: 'Agosto' },
  { value: '8', name: 'Septiembre' },
  { value: '9', name: 'Octubre' },
  { value: '10', name: 'Noviembre' },
  { value: '11', name: 'Diciembre' },
  { value: '12', name: 'Enero' },
];

export const YEAR_OPTIONS = Array.from(
  { length: differenceInYears(new Date(), new Date().setFullYear(2021)) + 1 },
  (_, i) => i
).map((i) => ({
  value: (2021 + i).toString(),
  name: (2021 + i).toString(),
}));

export const STATUS_OPTIONS = [
  { value: 'general', name: 'GRÁFICOS GENERALES' },
  ...[
    RECORD_STATUS.NEW,
    RECORD_STATUS.IN_EXCHANGE,
    RECORD_STATUS.IN_LOAN,
    RECORD_STATUS.RESOLVED,
    RECORD_STATUS.CHARGED,
    RECORD_STATUS.DISCOUNT,
    RECORD_STATUS.NOT_RECOVERED,
  ].map((obj) => ({ value: obj, name: RECORD_STATUS_TEXT[obj] })),
];

export const ALERT_KIND_TEXT = {
  cevit_pending: 'EXPEDIENTE PENDIENTE DE INGRESO A CEVIT',
  cevit_fix: 'EXPEDIENTE PARA SUBSANAR OBSERVACION DE CEVIT',
  upe_pending: 'EXPEDIENTE PENDIENTE DE INGRESO A UPE',
  resolved_response: 'EXPEDIENTE PARA RESPONDER RESOLUCIÓN',
  recovered: 'EXPEDIENTE PARA SOLICITAR REINTEGRO',
  comeci_problem: 'EXPEDIENTE CON PROBABLE INGRESO A COMECI',
};

export const ALERT_LEVEL_OPTIONS = [
  { value: 'all', name: 'Todos' },
  { value: 'low', name: 'Bajo' },
  { value: 'medium', name: 'Moderado' },
  { value: 'high', name: 'Alto' },
];

export const ALERT_KIND_OPTIONS = [
  { value: 'all', name: 'Todos' },
  ...Object.keys(ALERT_KIND).map((key) => ({
    value: ALERT_KIND[key as keyof typeof ALERT_KIND],
    name: ALERT_KIND_TEXT[ALERT_KIND[key as keyof typeof ALERT_KIND]],
  })),
];

export const LICENSE_OPTIONS = [
  { value: 'basic', name: 'BÁSICO', max_records: 20 },
  { value: 'medium', name: 'INTERMEDIO', max_records: 50 },
  { value: 'advanced_1', name: 'AVANZADO 1', max_records: 100 },
  { value: 'advanced_2', name: 'AVANZADO 2', max_records: 200 },
  { value: 'unlimited', name: 'ILIMITADO', max_records: 100_000 },
];
